.app-icon {
    background-image: url('../../assets/GoalTasksIcon.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-clip: border-box;
    width: 96px;
    height: 96px;
    margin: 0 auto;
}

#goaltasks-main-grid {
    padding: 0;
}

#goaltasks-main-grid #goaltasks-main {
    padding: 55px 30px 85px 30px;
}

#goaltasks-main .tagline {
    font-size: 48px;
    font-weight: 600;
    text-align: center;
}

#goaltasks-main .intro-text {
    font-size: 21px;
    font-weight: 400;
    text-align: center;
}

#goaltasks-main .appName {
    font-size: 21px;
    font-weight: 700;
    text-align: center;
    margin: 0;
}

#goaltasks-main .download-cell {
    text-align: center;
    
}

#goaltasks-main .appName h4 {
    margin: 0;
}

#goaltasks-main .intro-body {
    margin-top: 6em;
}

#goaltasks-main .tagline {
    margin: 24px 0;
} 

#goaltasks-main .intro-text {
    margin-bottom: 32px;
} 

#goaltasks-main-grid .demo-section {
/*     
    width: 80%;
    margin: 0 auto; */
    padding: 64px 48px 64px 42px; 
}

#goaltasks-main-grid video {
    width: 100%;
    margin-bottom: 2em;
}

#goaltasks-main-grid .demo-section .feature-set {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
#goaltasks-main-grid .demo-section .feature
   {
    width: 48%;
}

 

#goaltasks-main-grid .demo-section .feature > div:nth-of-type(1) {
    font-weight: 500;
    font-size: 20px;
}

#goaltasks-main-grid .demo-section .feature > div:nth-of-type(2)
  {
    font-weight: 400;
    font-size: 18px;
}

@media (min-width: 600px) {
    .app-icon {
        
        width: 230px;
        height: 224px;
        margin: 0 auto;
    }

    #goaltasks-main {
        width: 68%;
        margin: 0 auto;
    }

    #goaltasks-main-grid .demo-section {
        padding: 9.5%; 
    }

    .goaltasks-video-cell {
        width: 45%;
         margin: 0 auto;
    }

    #goaltasks-main-grid .feature-set > .set-goal,
    #goaltasks-main-grid .feature-set > .accomplish-goals  {
        width: 21%;
        margin: 0 auto;
    }

    #goaltasks-main-grid .feature-set > .complete-actions {
        width: 23.5%;
        margin: 0 auto;
    }
}



