.about-grid-container {
    padding: 0 1em;
} 
 .about-grid-container .about-subtitle1 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: .5em;
}

.about-grid-container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.about-grid-container .about-pic {
    width: 80px;
    height: 80px;
    border-radius: 100%;
}

.about-grid-container .about-cell {
    margin-top: 1em;
}

.about-cell:nth-of-type(3) div {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

.about-cell:nth-of-type(3) div span {
    margin-left: 1em;
}

#open-cv {
    width: 100%;
    display: block;
    text-align: center;
    margin: 2em 0;
}

#open-cv .btn {
    width: 40%;
    background-color: black;

}

@media (max-width: 839px) and (min-width: 480px) {
    .about-grid-container {
        padding-left: 2em !important;
        padding-right: 2em !important;
        height: calc(100vh - 271px);
    }

    .about-grid {
        grid-template-columns: repeat(8, minmax(0, 74px));
        padding-left: 74px;
        padding-right: 74px;
    }

    .about-subtitle1 {
        font-size: 24px !important;
    }
}

@media (min-width: 840px) {
    .about-grid-container {
        padding-top: 4em !important;
        padding-left: 10.15% !important;
        padding-right: 10.15% !important;
    }

    .about-subtitle1 {
        font-size: 28px !important;
    }

    .about-cell {
        width: 66% !important;
        margin: 0 auto !important;
    }

    .mdc-typography--body1 {
        font-size: 18px !important;
    }
}

/*
.about-grid-container {

    .about-subtitle1 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: .5em;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .about-pic {
        width: 80px;
        height: 80px;
        border-radius: 100%;
    }

    .about-cell {
        margin-top: 1em;
    }

    .about-cell:nth-of-type(3) {
        div {
            display: flex;
            align-items: center;
            margin-bottom: 1em;
            span {
                margin-left: 1em;
            }
        }
    }
}

@media (max-width: 839px) and (min-width: 480px) {
    .about-grid-container {
        padding-left: 2em !important;
        padding-right: 2em !important;
        height: calc(100vh - 271px);
    }

    .about-grid {
        grid-template-columns: repeat(8, minmax(0, $colWidth));
        padding-left: 74px;
        padding-right: 74px;
    }

    .about-subtitle1 {
        font-size: 24px !important;
    }
}

@media (min-width: 840px) {
    .about-grid-container {
        padding-top: 4em !important;
        padding-left: 10.15% !important;
        padding-right: 10.15% !important;
        // height: calc(100vh - 335px);
    }

    // .about-grid {
    //     grid-template-columns: repeat(8, minmax(0, $colWidth));
    //     padding-left: 74px;
    //     padding-right: 74px;
    // }

    .about-subtitle1 {
        font-size: 28px !important;
    }

    .about-cell {
        width: 66% !important;
        margin: 0 auto !important;
    }

    .mdc-typography--body1 {
        font-size: 18px !important;
    }
}
*/