.App {
  font-family: 'Raleway', sans-serif;

}

.App .mdc-top-app-bar, 
.footer {
  background-color: #222222;
}

.App .footer .link {
  color: white;
  font-weight: 600;
  text-decoration: underline;
  
}

.App .footer {
  list-style-type: none;
  height: 128px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
 
}

.App .footer .item {
  font-weight: 700;
}

.App .footer .privacy {
  margin-bottom: 1em;
 
}

.App .footer .connect > div:nth-of-type(1) {
  text-decoration: none;
}

.App .footer .connect {
  text-align: center;
}

.App .footer .connect .link {
  padding: .5em;
  display: block;
}

 .mdc-top-app-bar__section {
  justify-content: center;
}

#app-title {
    font-size: 21px;
    font-weight: 700;
}

.tagline {
  font-size: 48px;
  font-weight: 600;
}

.intro {
  font-size: 21px;
  font-weight: 400;
}

.headline {
  font-size: 19px;
  font-weight: 700;
}

.subheadline {
  font-size: 17px;
  font-weight: 400;
}

#topbar-title {
  color: white;
}

.nav-link {
  text-decoration: none;
  margin-right: auto;

   
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 56px !important;
}

