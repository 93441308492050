.container {
    /* width: 100%; */
    padding: 1em;
}

.container .react-player {
    padding-top: 16px;
    margin: 0 auto;
}

.container .category-title {
    text-transform: capitalize;
}

.container .card-item {
    border-radius: 25px;
    margin-bottom: 2em;
    width: 100%;
}

.container .screen-intro {
    padding: 1em 0;
}

@media (min-width: 768px) {

    .container .videos {
        
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        

    }

    .container .card-item {
        width: 48%;
    }

    .headline {
        font-size: 24px;
    }
}

@media (min-width: 1024px) {

    .container .card-item {
        width: 33%;
    }

    .headline {
        font-size: 32px;
    }
}