.top {
    height: 60vh;
    background-color: green;
}

.bottom {
    height: 50vh;
    background-color: hotpink;
}

#currencyBase {
    text-align: center;
 
}

#currencyBase .banner {
    background-color: #0460D1;
    padding-bottom: 0;
    color: #fff;
}

#currencyBase .mdc-layout-grid__inner {
    height: 100%;
}

#currencyBase #app-title {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 32px;

    display: flex;
    justify-content: center;
    align-items: center;
 }

 #currencyBase #app-title .app-icon {

     width: 55px;
     height: 55px;
     border-radius: 10px;
     margin: 0 16px 0 0;
 }

#currencyBase .appImg {
    text-align: center;
}

#currencyBase .intro {
    margin: 16px 0;
}

#currencyBase .appImg img {
    display: inline-block;
    width: 289px;
}

#currencyBase .intro #download-btn {
    
    height: 54px;
    width: 164px;
    display: block;
    
    margin: 0 auto;
    margin-top: 32px;
}

#currencyBase #download-btn img {
    width: 100%
}

#currencyBase .alice-carousel__dots {
    margin-top: 0;
}

@media(min-width: 840px) {

    #currencyBase .text-container {
        padding-left: 64px
    }

    #currencyBase #app-title {
        justify-content: flex-start;
    }

    .tagline, 
    .intro {
        text-align: left;
    }

    #currencyBase .appImg img {
        margin-top: 6.5em;
    }

   

    /* #currencyBase .banner {
        height: 60vh !important;
    } */

    #currencyBase .img-container {

        display: flex;
         align-items: flex-end;
        justify-content: center;
    }

    #currencyBase .intro #download-btn {
         margin: 2em 0 0 0;
        display: block;
         border-top-right-radius: 13px;
        border-top-left-radius: 13px;
        border-bottom-left-radius: 13px;
        border-bottom-right-radius: 13px;
        width: 250px;
        height: 83px;
        
    }

    #currencyBase #download-btn img {
        width: 100%;
        height: 100%;
    }

  

    /* #currencyBase .mdc-layout-grid__inner {
        height: 100%;
    } */

   
}