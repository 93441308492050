.feature-img {
    width: 183px;
    height: 371px;
}

.headline {
    margin-bottom: 16px;
}

@media (min-width: 600px) {
    #currencyBase .feature {
        display: flex;
        width: 80%;
        margin: 0 auto;
    }

    #currencyBase .feature-caption {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 3em;
    }

    #currencyBase .feature-caption .subheadline {
       text-align: left;
    }
}