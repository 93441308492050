#privacy-main {
    height: 100vh;
}

.header-cell > div:nth-of-type(1) {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 1em;
}

.header-cell > div:nth-of-type(2) {
    font-size: 16px;
    font-weight: 500;
}

.privacy-item-cell  {
    margin-top: 1.5em;
}

.privacy-item-cell, .header-cell {
    text-align: center;
}

.privacy-item-cell > div:nth-of-type(1) {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: .5em;
}

.privacy-item-cell > div:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
}

@media (min-width: 600px) {
    .header-cell {
        width: 36%;
        margin: 0 auto;
    }

    .privacy-item-cell {
        width: 60%;
        margin: 0 auto;
    }
}

/* mobile @media (max-width: 599) */
/* ipad @media (max-width: 839px) and (min-width: 600px) */